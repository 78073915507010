import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'remix';

export const CatchErrServer: React.VFC = () => {
    const [showError, setShowError] = useState(false);
    const url = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const redirectMappings: { [key: string]: string } = {
            '/menu/purin/': '/category/menu/detail?article_id=purin',
            '/menu/anko/': '/category/menu/detail?article_id=anko',
            '/purpose/date/': '/category/purpose/detail?article_id=date',
            '/menu/cheesecake/': '/category/menu/detail?article_id=cheesecake',
            '/menu/maccha/': '/category/menu/detail?article_id=matcha',
            '/menu/scone': '/category/menu/detail?article_id=scone',
            '/purpose/friend/': '/category/purpose/detail?article_id=friend',
            '/purpose/mornig/': '/category/purpose/detail?article_id=morning',
            '/menu/pancake/': '/category/menu/detail?article_id=pancake',
            '/purpose/single/': '/category/purpose/detail?article_id=single',
            '/menu/cannele/': '/category/menu/detail?article_id=canele',
            '/menu/tiramisu/': '/category/menu/detail?article_id=tiramisu',
            '/menu/fruit_sandwich/': '/category/menu/detail?article_id=fruit_sandwich',
            '/menu/chocolat/': '/category/menu/detail?article_id=chocolate',
            '/menu/parfait/': '/category/menu/detail?article_id=parfait',
            '/purpose/takeout/': '/category/purpose/detail?article_id=takeout',
            '/menu/donut/': '/category/menu/detail?article_id=donut',
            '/category/menu/': '/category/menu',
            '/category/purpose/': '/category/purpose',
            '/purpose/instagenic': '/category/purpose/detail?article_id=Instagenic',
            '/category/area/': '/category/area',
            '/category/category/': '/category/category'
        };

        if (redirectMappings[url.pathname]) {
            console.log(`Redirecting from ${url.pathname} to ${redirectMappings[url.pathname]}`);
            navigate(redirectMappings[url.pathname]);
        }

        const timer = setTimeout(() => {
            setShowError(true);
        }, 1500); // 500ms待つ

        return () => clearTimeout(timer); // コンポーネントがアンマウントされた場合にタイマーをクリア
    }, [url, navigate]);

    return (
        <>
            {showError && (
                <div className={"errContent"}>
                    <h2>
                        <img className='errContentImg' src="/images/shared/notFound.png" alt="404" />
                        <button className='errContenButton'><Link to="/">ホームに戻る</Link></button>
                    </h2>
                </div>
            )}
        </>
    );
};
