import { Outlet, useCatch } from "remix";
import { CatchErrServer } from "~/components/CatchErrServer";
import { MetaHeader } from "~/components/MetaHeader";
import { DefaultLayout } from "~/layouts/DefaultLayout";
import { useScrollToTop } from "~/hooks/useScrollToTop";
export default function App() {
  useScrollToTop()
  return (
    <html lang="ja">
      <Outlet />
    </html>
  );
}

export function CatchBoundary() {
  useScrollToTop()
  let caught = useCatch();
  let message;

  switch (caught.status) {
    case 404:
      message = (
        <span>お探しのページが<br />見つかりませんでした</span>
      );
      break;
    default:
      throw new Error(caught.data || caught.statusText);
  }
  return (
    <html>
      <MetaHeader title={"福岡カフェ日記とは - 福岡カフェ日記 | 福岡最大級のカフェメディア"} description={"福岡のカフェをキュレーションしたメディア、福岡カフェ日記。みなさまの好きが詰まったお店を紹介しています。ページをめくるように覗いていただけると嬉しいです。"} />
      <DefaultLayout>
        <CatchErrServer />
      </DefaultLayout>
    </html>
  );
}